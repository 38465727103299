import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import isEmpty from '../../utilities/isEmpty';
import { getConfig } from 'utilities/config';
import parseError from 'utilities/parseError';

const config = getConfig();

export const updateName = createAsyncThunk(
    'profile/updateName',
    async ({ values, setSubmitting, successCallback, failureCallback }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${config.NEXT_PUBLIC_BASE_URL}/profile/updatename`,
                values,
            );

            if (!isEmpty(setSubmitting)) {
                setSubmitting(false);
            }

            if (!isEmpty(successCallback)) {
                successCallback();
            }

            return data;
        } catch (error) {
            if (!isEmpty(setSubmitting)) {
                setSubmitting(false);
            }
            var parsedErrorMessage = parseError(error);

            if (!parsedErrorMessage.manualDisplay && !isEmpty(failureCallback)) {
                failureCallback();
            }

            return rejectWithValue(parsedErrorMessage);
        }
    },
);

export const changePassword = createAsyncThunk(
    'profile/changePassword',
    async ({ values, setSubmitting, successCallback, failureCallback }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${config.NEXT_PUBLIC_BASE_URL}/profile/changepassword`,
                values,
            );

            if (!isEmpty(setSubmitting)) {
                setSubmitting(false);
            }

            if (!isEmpty(successCallback)) {
                successCallback();
            }

            return data;
        } catch (error) {
            if (!isEmpty(setSubmitting)) {
                setSubmitting(false);
            }
            var parsedErrorMessage = parseError(error);

            if (!parsedErrorMessage.manualDisplay && !isEmpty(failureCallback)) {
                failureCallback();
            }

            return rejectWithValue(parsedErrorMessage);
        }
    },
);

export const changeEmail = createAsyncThunk(
    'profile/changeEmail',
    async ({ values, setSubmitting, successCallback, failureCallback }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${config.NEXT_PUBLIC_BASE_URL}/profile/changeemail`,
                values,
            );

            if (!isEmpty(setSubmitting)) {
                setSubmitting(false);
            }

            if (!isEmpty(successCallback)) {
                successCallback();
            }

            return data;
        } catch (error) {
            if (!isEmpty(setSubmitting)) {
                setSubmitting(false);
            }
            var parsedErrorMessage = parseError(error);

            if (!parsedErrorMessage.manualDisplay && !isEmpty(failureCallback)) {
                failureCallback();
            }

            return rejectWithValue(parsedErrorMessage);
        }
    },
);

export const sendEmailVerification = createAsyncThunk(
    'profile/sendEmailVerification',
    async ({ successCallback, failureCallback }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${config.NEXT_PUBLIC_BASE_URL}/profile/sendemailverificationemail`
            );

            if (!isEmpty(successCallback)) {
                successCallback();
            }

            return data;
        } catch (error) {
            var parsedErrorMessage = parseError(error);

            if (!isEmpty(failureCallback)) {
                failureCallback();
            }

            return rejectWithValue(parsedErrorMessage);
        }
    },
);

export const confirmEmail = createAsyncThunk(
    'profile/confirmemail',
    async ({ values, successCallback, failureCallback }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(
                `${config.NEXT_PUBLIC_BASE_URL}/profile/confirmemail`,
                values,
            );

            if (!isEmpty(successCallback)) {
                successCallback();
            }

            return data;
        } catch (error) {
            var parsedErrorMessage = parseError(error);

            if (!isEmpty(failureCallback)) {
                failureCallback();
            }

            return rejectWithValue(parsedErrorMessage);
        }
    },
);


