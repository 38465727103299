import axios from 'axios';
import isEmpty from './isEmpty';
import { ERROR_ARRAY } from './constants';

const parseError = (error) => {
  // eg; Axios error
  if (axios.isAxiosError(error)) {
    const response = error?.response;

    // eg; network unavailable
    if (
      !isEmpty(error.code) &&
      !isEmpty(error.message) &&
      ERROR_ARRAY.includes(error.code)
    ) {
      if (response) {
        if (!isEmpty(response.data)) {
          return {
            code: response.data.statusCode,
            message: response.data.message,
            errors: response.data.errors,
            manualDisplay: response.data.manualDisplay,
          };
        }

        if (!isEmpty(response?.status)) {
          return {
            code: response?.status,
            message: error.message,
            manualDisplay: false,
          };
        }
      }

      return { code: error.code, message: error.message, manualDisplay: false };
    }
  }

  //eg; Failed message model
  if (!isEmpty(error.data)) {

    return {
      code: error.data.statusCode,
      message: error.data.message,
      manualDisplay: false,
    };
  }

  //RTK query messages
  if (!isEmpty(error.status) && !isEmpty(error.error)) {

    return { code: error.status, message: error.error, manualDisplay: false };
  }

  //uncaught exception handled by backend middleware
  if (!isEmpty(error.statusCode) && !isEmpty(error.message)) {

    if (!isEmpty(error.errors) && Array.isArray(error.errors) && error.errors.length > 0) {

      var message = '';
      for (var i = 0; i < error.errors.length; i++) {
        message = message + " " + error.errors[i].message;
      }

      return {
        code: error.statusCode,
        message: message,
        manualDisplay: false,
      };
    }

    return {
      code: error.statusCode,
      message: error.message,
      manualDisplay: false,
    };
  }

  return error;
};

export default parseError;
