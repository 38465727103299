import { createSlice } from '@reduxjs/toolkit';
import {
  userLogin,
  userRegistration,
  sendOtp,
  verifyOtp,
  impersonate,
  confirmResetPassword,
} from './authActions';
import isEmpty from '../../utilities/isEmpty';
import { loadInitialData } from '../home/homeActions';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { AuthForms } from 'utilities/constants';
import { changeEmail, changePassword, confirmEmail, updateName } from './profileActions';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    dialogOpen: false,
    formIndex: 0,
    token: null,
    permissions: null,
    error: null,
    impersonator: { token: null, permissions: null },
    userHash: null,
    otpTimeToWait: null
  },
  reducers: {
    toggleDialogOpen: (state) => {
      state.dialogOpen = !state.dialogOpen;
    },

    setFormIndex: (state, action) => {
      state.error = null;
      state.formIndex = action.payload;
    },

    logOut: (state, action) => {
      const cookies = new Cookies();
      cookies.remove('token');
      state.token = null;
      state.permissions = null;
    },

    setUserPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    // userLogin
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
        state.permissions = null;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.userHash)) {
          state.userHash = payload.userHash;
          state.formIndex = AuthForms.SEND_OTP;
        }
        else {
          state.permissions = payload.userPermissions;
          state.token = payload.jwtToken;
        }
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });
    // userRegistration
    builder
      .addCase(userRegistration.pending, (state, action) => {
        state.loading = true;
        state.userHash = null;
        state.error = null;
      })
      .addCase(userRegistration.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userHash = payload.userHash;
        state.formIndex = AuthForms.SEND_OTP;
      })
      .addCase(userRegistration.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });

    // loadInitialData
    builder.addCase(loadInitialData.fulfilled, (state, { payload }) => {
      state.countries = payload.countries;

      if (!isEmpty(payload?.userPermissions)) {
        state.permissions = payload.userPermissions;
      }

      if (!isEmpty(payload?.jwtToken)) {
        state.token = payload.jwtToken;
      }
    });
    // sendOtp
    builder
      .addCase(sendOtp.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOtp.fulfilled, (state, { payload }) => {
        state.formIndex = AuthForms.ENTER_OTP;
        state.otpTimeToWait = payload.otpTimeToWait;
        state.loading = false;

        if (!isEmpty(payload.userPermissions)) {
          state.permissions = payload.userPermissions;
        }
      })
      .addCase(sendOtp.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });
    // verifyOtp
    builder
      .addCase(verifyOtp.pending, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        state.permissions = payload.userPermissions;
        state.token = payload.jwtToken;
        // state.dialogOpen = false;
        state.loading = false;
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + payload.jwtToken;
      })
      .addCase(verifyOtp.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });

    // impersonate
    builder.addCase(impersonate.fulfilled, (state, { payload }) => {
      if (!isEmpty(payload?.userPermissions) && !isEmpty(payload?.jwtToken)) {
        state.impersonator = {
          token: state.token,
          permissions: state.permissions,
        };
        state.permissions = payload.userPermissions;
        state.token = payload.jwtToken;
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + payload.jwtToken;
      }
    });
    //profile - updatename
    builder
      .addCase(updateName.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateName.fulfilled, (state, { payload }) => {
        state.permissions = payload.userPermissions;
      })
      .addCase(updateName.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });
    //profile - change password
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.permissions = payload.userPermissions;
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });

    //profile - change email
    builder
      .addCase(changeEmail.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeEmail.fulfilled, (state, { payload }) => {
        state.permissions = payload.userPermissions;
      })
      .addCase(changeEmail.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });

    //profile - confirm email
    builder
      .addCase(confirmEmail.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(confirmEmail.fulfilled, (state, { payload }) => {
        state.permissions = payload.userPermissions;
      })
      .addCase(confirmEmail.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });
    //auth - confirmResetPassword
    builder
      .addCase(confirmResetPassword.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(confirmResetPassword.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.permissions = payload.userPermissions;
        state.token = payload.jwtToken;
      })
      .addCase(confirmResetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        if (!isEmpty(payload.manualDisplay) && payload.manualDisplay) {
          state.error = payload;
        }
      });
  },
});

export const { toggleDialogOpen, setFormIndex, logOut, setUserPermissions } = authSlice.actions;

export default authSlice.reducer;
