import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import isEmpty from '../../utilities/isEmpty';
import parseError from 'utilities/parseError';
import { getConfig } from 'utilities/config';
import Cookies from 'universal-cookie';
import { setJwtToken } from 'utilities/setJwtToken';


const config = getConfig();

export const loadInitialData = createAsyncThunk(
  'home/loadInitialData',
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const cookies = new Cookies();

      let token = cookies.get('token');

      if (!isEmpty(token)) {
        setJwtToken(token);
      }

      const { data } = await axios.get(`${config.NEXT_PUBLIC_BASE_URL}/home`);

      return {
        userPermissions: data.userPermissions,
        countries: data.countries,
        jwtToken: token,
      };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);
