import axios from 'axios';
import Cookies from 'universal-cookie';

export const setJwtToken = (jwtToken) => {
    const cookies = new Cookies();

    var date = new Date();
    date.setDate(date.getDate() + 365);

    cookies.set('token', jwtToken, { path: '/', expires: date, secure: true });

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
}